import { createRouter, createWebHistory } from 'vue-router'

// import  FirstPage from '../views/FirstPage.vue'
// import  RegPage from '../views/RegPage.vue'
// import  EnterPage from '../views/EnterPage.vue'
// import  ClosePage from '../views/ClosePage.vue'
// import  EditPage from '../views/EditPage.vue'
import  NotFound from '../views/NotFound.vue'





const routes = [
  { path: '/', component: () => import("../views/FirstPage.vue") },
  { path: '/reg', component: () => import("../views/RegPage.vue") },
  { path: '/enter', component: () => import("../views/EnterPage.vue") },
  { path: '/info', component: () => import("../views/InfoPage.vue") },
  { path: '/thanks', component: () => import("../views/ThanksPage.vue") },
  { path: '/сompany', component: () => import("../views/CompanyPage.vue") },
  { path: '/сompany', component: () => import("../views/CompanyPage.vue") },
  { path: '/information', component: () => import("../views/InformationPage.vue") },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
    
  }
]



const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active-link-color'
})



export default router
