<template>
<TheMenu/>
<!-- <TheBurger/> -->
<div class="container">

<router-view v-slot="{ Component}">
<transition name="scale" mode="out-in">
  <component :is='Component'></component>
</transition>
</router-view>
</div>

<div class="container">
<TheForm/>
</div>

</template>

<script>
import TheMenu from '@/components/TheMenu.vue'
// import TheBurger from '@/components/TheBurger.vue'
import TheForm from '@/components/TheForm.vue'

export default {
  components: {
    TheMenu,
    TheForm
   
  }
}
</script>

<style>
.container{
max-width: 1200px;
height: auto;
margin-top: 20px;
margin-bottom: 200px;
margin-left: auto;
margin-right: auto;
}

h1 {
    background:  linear-gradient(to right, #0066ff 0%, #ffffff 51%, #005aff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 106px;
    text-align: left;
    line-height: 1.1;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 900;
    letter-spacing: -1.5px;
    /* margin: 0 0 20px 0; */
}
h2 {
    color: white;
    font-size: 66px;
    text-align: left;
    line-height: 1.1;
    margin: 0;
    font-weight: 900;
    letter-spacing: -1.5px;
    /* margin: 0 0 20px 0; */
}
h3 {
    color: white;
    font-size: 30px;
    text-align: left;
    line-height: 1;
    margin: 0;
    font-weight: 500;
    letter-spacing: 1,2px;
    /* margin: 0 0 20px 0; */
}
h4 {
     background:  linear-gradient(to right, #ffffff 0%, #8500ff 51%, #9100ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    text-align: left;
    line-height: 1;
    margin: 0;
    font-weight: 700;
    letter-spacing: 1,2px;
    /* margin: 0 0 20px 0; */
}
/* style of button */
 .btn-grad {
     background-image: linear-gradient(to right, #001081 0%, #00a1ff  51%, #9100ff  100%)
    }
 .btn-grad {
   width: 250px;
    padding: 15px 45px;
    margin-top: 30px;
    margin-right: 10px;
    text-align: center ;
    transition: 0.5s;
    background-size: 200% auto;
    color: #ffffff;            
    border-radius: 8px;
        border-color: #028ebb;
    border-top: 1px solid rgba(3,143,188,.16);
    border-bottom: 1px solid rgba(46,162,200,.29);
    border-style: none;
    cursor: pointer;
     font-size: 14px;
    letter-spacing: 3px;
 
  }
  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
   .btn-grad-border {
     width: 240px;
     margin-right: 10px;
    padding: 15px 45px;
    margin-top: 30px;
    text-align: center;
    color: #ffffff;   
    background-color: #6764ff26;         
    border-radius: 8px;
    border:  1px solid #26d0ce5c;
    cursor: pointer;
     font-size: 14px;
    letter-spacing: 4px;
   }
   .btn-grad-border:hover {
          background-color: #6764ff40;    
   }
   /* menu style  */
.block {
	color: #ffffff;
	padding: 10px 30px;
	font-size: 16px;
	border-radius: 4px;
	position: relative; 
}
.block:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 4px;
	background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
	z-index: -1;
	animation: blockanimate 3s ease alternate infinite;
	background-size: 300% 300%;
	clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
}
 
@keyframes blockanimate {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/*  */
.logo {
  margin-left: 10px;
  font-weight: 900;
  color: white;
}
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 48px;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  
}
li {
  display: inline-block;
  margin: 0 10px;
 
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}
.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
@media (max-width:768px){
    h1 {
      font-size: 60px;
      text-align: center;
    }
      h2 {
      font-size: 40px;
      text-align: center;
    }
    
    .btn-grad {
      width: 100%;
      margin-top: 10px;
  
    }
 
   }
</style>
