<template>
<h1>Not Found</h1>
<p> Мы не смогли найти такую страницу...</p>
<router-link to="/">Перейти на главную страницу</router-link>
</template>

<style scoped>
h1 {
    text-align: center;
}
p{
    text-align: center;
}
</style>