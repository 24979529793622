<template>

<nav class="menu">
   <router-link to="/"><div class="logo"> <img src="Logo.png" width="40" alt="logo" class=""> OTZOVIK</div></router-link>
  <ul>
    <router-link to="/reg">  <li><div class="block">Присоединится к сервису</div></li></router-link>
  </ul>

</nav>
</template>

<script setup>

import { onMounted, ref } from 'vue';
import { getAuth, onAuthStateChanged, signOut } from '@firebase/auth';
import { useRouter } from "vue-router";
  
  
  const router = useRouter();
  const isLoggedIn = ref(false);

    let auth;
    onMounted(() => { // Registers a callback to be called after the component has been mounted
     auth = getAuth();
     onAuthStateChanged(auth, (user) => {
       if (user){
        isLoggedIn.value = true;
       } else {
        isLoggedIn.value = false;
       }
     });
});
    const handleSignOut = () => {
      signOut(auth).then(() => {
        router.push("/");
      });
    } 
console.log(handleSignOut)

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*  */

.block {
	color: white;
	padding: 10px 30px;
	font-size: 16px;
	border-radius: 4px;
	position: relative; 
}
.block:after {
	content: '';
  color: transparent;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 4px;
	background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
	z-index: -1;
	animation: blockanimate 3s ease alternate infinite;
	background-size: 300% 300%;
	clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
}
 
@keyframes blockanimate {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
/*  */
 
ul .active-link-color{
 color: pink;

}
.logo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 30px;
  font-weight: 900;
  color: white;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 50px;
  height: 48px;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  
}
li {
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;


}
@media (max-width:786px){
  .block {
	color: white;
	padding: 10px;
	font-size: 10px;
	border-radius: 4px;
	position: relative; 
}
.logo {
  margin-left: 0px;
  font-size: 11px;
}
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px;
}
}
</style>
