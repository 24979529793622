import { createStore } from 'vuex'

// Create a new store instance.
 const store = createStore({
    state: {   
     closePage:{
       name: 'Закрытая страница',
       description: 'Вы успешно зарегистрировались на нашем сайте!',
       description_two: 'Вам доступна следующая информация:'
     },
     baseData: {
      info_tel: '',
      info_name: ''    
    },
    dataTo: []

    },
    getters:{
      GET_DATA(state){
        return state.closePage;
      }
    },
    mutations: { // for change state data
    
      ADD_TOBASE(state) {
         state.dataTo.push(store.baseData);
         console.log(this.$store.state.dataTo);
      }
    },
    actions:{

  }
})

  export default store