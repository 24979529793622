<template>

<div v-if="send === false" class="info_blok">
<div class="info_text">
<h3>Хотите узнать больше о проекте?</h3>
<p> Или вас интересует другая информация, просто закажите звонок</p>
</div>

<div class="info_form">
<!-- start form -->
<form  @submit.prevent name="info_form" method="post">
    <input type="tel" placeholder="Ваш телефон" v-model="info_tel"   required>
    <input type="text" placeholder="Ваше Имя" v-model="info_name"   required>
    <!-- <p> {{ this.$store.state.baseData.info_tel }} </p> -->
    <button @click="infoForm()" type="submit" class="btn-grad info-grad">Отправить</button>
</form>
<!-- the end -->
</div>
</div>
<!-- <h4 v-else>В самое ближайшее время мы перезвоним вам!</h4> -->
</template>

<script>
import axios from 'axios'

export default{
  data(){
    return {
        send: false,
        info_tel: '',
        info_name: ''    
    }
  },
  methods: {

    infoForm(){
      let formData = new FormData();
      formData.append('info_tel', this.info_tel);
      formData.append('info_name', this.info_name);
      this.send = true;
       console.log(this.selected);
      if( this.info_tel && this.info_name ){
        
        axios.post('info.php',
        formData,
        {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
      }
    ).then(() => {
              console.log('res'); 
              this.$router.push('/information');   
            
    }).catch(function (error) {
    // handle error
    console.log(error);
  })   
} 
    }
    }
  
  }



</script>

<style scoped>
.info_blok {
    height: auto;
    margin-bottom: 150px;
}
.info_text {
    text-align: left;
}
form[name='info_form']{
   display: flex;
   justify-content: space-between;
   align-items: center;
   align-content: center;
   height: auto;
   font-size: 16px;
    font-family: inherit;
}
form[name='info_form'] input {  
    width: 100%;
    padding: 15px;
    margin-right: 10px;
    font-size: 16px;
    font-family: inherit;
    color: #ffffff;   
    background-color: #6764ff26;         
    border-radius: 8px;
    border:  1px solid #26d0ce5c;
    cursor: pointer;
}
input {outline: none;}
input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px rgb(16, 0, 62) !important; /* Цвет фона */
    -webkit-text-fill-color: #999 !important; /* цвет текста */
    color: rgb(3, 25, 139) !important; /* цвет текста */
}
.info-grad {
    margin-top: 0;
}
@media (max-width:786px){
 form[name='info_form']{
   flex-direction: column;


}
  form[name='info_form'] input{
    margin: 5px auto;
  }
  .info-grad {
    margin-top: 30px;
    width: 100%;
}
}
</style>